import React, { useState, useEffect } from "react";
import UserService from "../services/user.service";
import { RiseLoader } from "react-spinners";
import swal from "sweetalert2";

import Header from "../components/header-component";
import Footer from "../components/footer";

import {
  getShoppingCartData,
  removeCartItemPOST,
  updateCartItemPOST,
} from "../services/apiServices";
import useCartStore from "../common/globalStore";
import { Link } from "react-router-dom";

export default function shoppingCart() {
  //const [shoppingCartState, setShoppingCartState] = useState(null);
  // const [shoppingCartItemsState, setShoppingCartItemsState] = useState(null);
  // const [cartloading, setCartLoading] = useState(false);

  const shoppingCartState = useCartStore((state) => state.cartData);
  const shoppingCartItemsState = useCartStore(
    (state) => state.cartData.products
  );
  const cartloading = useCartStore((state) => state.cartloading);
  const getCartData = useCartStore((state) => state.updateCart);

  //remove cart item
  const removeCartItem = async (cartProductId) => {
    removeCartItemPOST(cartProductId)
      .then((res) => {
        swal.fire({
          position: "top",
          icon: "success",
          title: "Success",
          text: "Items has been deleted from Shopping Cart!",
          showConfirmButton: false,
          timer: 1500,
        });
        getCartData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //increment item quantity
  const onAddQty = (cartProductId) => {
    const onAddState =
      shoppingCartItemsState != null &&
      shoppingCartItemsState.map((item) => {
        if (item.cartProductId === cartProductId) {
          updateCartItem(cartProductId, item.quantity + 1);
          return (item = { ...item, quantity: item.quantity + 1 });
        } else {
          return item;
        }
      });
    // console.log(onAddState);
    // setShoppingCartItemsState(onAddState);
  };

  //decrement item quantity
  const onRemoveQty = (cartProductId) => {
    const onRemoveState =
      shoppingCartItemsState != null &&
      shoppingCartItemsState.map((item) => {
        if (item.cartProductId === cartProductId) {
          if (item.quantity - 1 != 0)
            updateCartItem(cartProductId, item.quantity - 1);
          return (item = { ...item, quantity: item.quantity - 1 });
        } else {
          return item;
        }
      });

    // setShoppingCartItemsState(onRemoveState);
  };

  // console.log(shoppingCartItemsState);

  //update cart item quantity
  const updateCartItem = async (cartProductId, quantity) => {
    updateCartItemPOST(cartProductId, quantity)
      .then((res) => {
        getCartData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    (async () => {
      await getCartData();
    })();
  }, []);

  return (
    <React.Fragment>
      {/* Header Component start */}
      <Header />
      {/* Header Component end */}
      {!cartloading ? (
        <div className="spinner">
          <RiseLoader color="#263c97" size={20} />
        </div>
      ) : (
        <main>
          {/* page-banner-area-start */}
          {!cartloading ? (
            <RiseLoader color="#ffffff" size={10} />
          ) : shoppingCartState.cartId === 0 ? (
            <div
              className="page-banner-area page-banner-height-2"
              data-background="assets/img/banner/page-banner-4.jpg"
            >
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="page-banner-content text-center">
                      <h4 className="breadcrumb-title">Your Cart</h4>
                      <div className="breadcrumb-two">
                        <nav>
                          <nav className="breadcrumb-trail breadcrumbs">
                            <ul className="breadcrumb-menu">
                              <li className="breadcrumb-trail">
                                <a href="/home">
                                  <span>Home</span>
                                </a>
                              </li>
                              <li className="trail-item">
                                <span>Cart</span>
                              </li>
                            </ul>
                          </nav>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <section className="breadcrumb__area box-plr-75">
              <div className="container">
                <div className="row">
                  <div className="col-xxl-12">
                    <div className="breadcrumb__wrapper">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <a href="/home">Home</a>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Cart
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          {/* page-banner-area-end */}

          {/* cart-area-start */}
          {!cartloading ? (
            <RiseLoader color="#ffffff" size={10} />
          ) : (
            <div>
              {shoppingCartItemsState != null ? (
                <section
                  className="cart-area pt-120 pb-120"
                  style={{ paddingTop: 0 }}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <form action="#">
                          <div className="table-content table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th className="product-thumbnail">Images</th>
                                  <th className="sku">SKU</th>
                                  <th className="ean">EAN</th>
                                  <th className="product-name">Product Name</th>
                                  <th className="product-quantity">
                                    Product Quantity
                                  </th>
                                  <th className="product-price">Price</th>
                                  <th className="Action">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {shoppingCartItemsState.map((el) => {
                                  return (
                                    <tr key={el.cartProductId}>
                                      <td className="product-thumbnail">
                                        <a>
                                          <img src={el.image} alt="" />
                                        </a>
                                      </td>
                                      <td className="sku">
                                        <Link
                                          to={
                                            "/product/" + el.productDetails.sku
                                          }
                                        >
                                          {el.productDetails.sku}
                                        </Link>
                                      </td>
                                      <td className="ean">
                                        <a>{el.productDetails.ean}</a>
                                      </td>
                                      <td className="product-name">
                                        <a>
                                          <b>{el.productDetails.productName}</b>
                                          <br />
                                          {el.model != null
                                            ? el.model.variantDesc
                                            : ""}
                                        </a>
                                        {el.promotion != null ? (
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: "10px",
                                            }}
                                          >
                                            {el.promotion.promotion.type == 1
                                              ? UserService.numberFormat(
                                                  el.promotion.value
                                                )
                                                  .toString()
                                                  .slice(0, -3) +
                                                " Cash Back - " +
                                                el.promotionExpiration
                                              : el.promotion.promotion.type == 2
                                              ? "Gift Item: Promotion " +
                                                el.promotionExpiration
                                              : ""}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                      <td className="product-quantity">
                                        <div className="cart-plus-minus">
                                          <input
                                            type="text"
                                            defaultValue={el.quantity}
                                            min="1"
                                          />
                                          <div
                                            className="dec qtybutton"
                                            style={{ zIndex: "1000" }}
                                            onClick={() =>
                                              onRemoveQty(el.cartProductId)
                                            }
                                          >
                                            -
                                          </div>
                                          <div
                                            className="inc qtybutton"
                                            style={{ zIndex: "1000" }}
                                            onClick={() =>
                                              onAddQty(el.cartProductId)
                                            }
                                          >
                                            +
                                          </div>
                                        </div>
                                      </td>
                                      <td className="product-price">
                                        <span className="amount">
                                          {UserService.numberFormat(
                                            el.sellingPrice
                                          )}
                                        </span>
                                      </td>
                                      <td className="Action">
                                        <a
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            removeCartItem(el.cartProductId)
                                          }
                                        >
                                          <img
                                            src="assets/img/Icon/delete.png"
                                            alt=""
                                          />
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>

                          <div className="row justify-content-end">
                            <div className="col-md-5">
                              <div className="cart-page-total">
                                <h2>Cart totals</h2>
                                <ul className="mb-20">
                                  <li>
                                    Net Amount{" "}
                                    <span>
                                      {UserService.numberFormat(
                                        shoppingCartState.cartTotal -
                                          (shoppingCartState.totalVat +
                                            shoppingCartState.totalDeliveyCost)
                                      )}
                                    </span>
                                  </li>
                                  <li>
                                    Shipping{" "}
                                    <span>
                                      {UserService.numberFormat(
                                        shoppingCartState.totalDeliveyCost
                                      )}
                                    </span>
                                  </li>
                                  <li>
                                    Vat Amount{" "}
                                    <span>
                                      {UserService.numberFormat(
                                        shoppingCartState.totalVat
                                      )}
                                    </span>
                                  </li>
                                  <li>
                                    Total{" "}
                                    <span>
                                      {UserService.numberFormat(
                                        shoppingCartState.cartTotal
                                      )}
                                    </span>
                                  </li>
                                </ul>
                                <a className="tp-btn-h1" href="/checkOut">
                                  Proceed to checkout
                                </a>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </section>
              ) : (
                ""
              )}
            </div>
          )}
          {/* cart-area-end  */}
        </main>
      )}
      {/* Footer Component start */}
      <Footer />
      {/* Footer Component end */}
    </React.Fragment>
  );
}

import axiosInstance from "./axiosInstance";

const user = JSON.parse(localStorage.getItem("user"));

//GET: Banner Data API
export async function getBannerData(type) {
  return await axiosInstance.get("api/app/banner/list/" + type);
}

//POST: Product API
export async function getProductData(
  sku,
  productId,
  modelId,
  categoryId,
  customerId
) {
  return await axiosInstance.post("api/app/product/details", {
    sku,
    productId,
    modelId,
    categoryId,
    customerId,
  });
}

//POST: Product search API
export async function getProductSearchData(categoryId, sku) {
  return await axiosInstance.post("api/app/product/productSearch", {
    categoryId,
    sku,
  });
}

//POST: Next Product Variant
export async function getNextVarAttrData(
  customerId,
  productId,
  variantLevel,
  noOfVarants,
  attrIds
) {
  return await axiosInstance.post("api/app/product/nextVariantAttr", {
    customerId,
    productId,
    variantLevel,
    noOfVarants,
    attrIds,
  });
}

//GET: Product Brands API // 1: B2B, 2:B2C
export async function getBrands() {
  return await axiosInstance.get("api/app/brand/list/1");
}

//GET: Brand data by id API
export async function getBrandData(brandId) {
  return await axiosInstance.get("api/app/brand/" + brandId);
}

//GET: Product Categories API // 1: B2B, 2:B2C
export async function getCategories() {
  return await axiosInstance.get("api/app/category/list/1");
}

//GET: Product Category Groups API // 1: B2B, 2:B2C
export async function getCategoryGroups() {
  return await axiosInstance.get("api/app/category/grouplist/1");
}

//GET: Product Categories parent with first child API // 1: B2B, 2:B2C
export async function getChildCategories(catId) {
  return await axiosInstance.get("api/app/category/child/" + catId + "/1");
}

//GET: Product Categories parent with child By brands API
export async function getChildCategoriesByCategoryBrands(catId, brand) {
  return await axiosInstance.get("api/app/category/" + catId + "/" + brand);
}

//GET: Product Categories by brands API
export async function getCategoriesByBrand(brandId) {
  return await axiosInstance.get("api/app/category/byBrand/" + brandId);
}

// GET: Associated Product API
export async function getAssociateProduct(productId, modelId) {
  return await axiosInstance.get(
    "api/app/product/accessories/" + productId + "/" + modelId
  );
}

// GET Cart Details API
export async function getShoppingCartData() {
  return await axiosInstance.get("api/app/cart/details/" + user.customerId);
}

// POST: Add To Cart API
export async function addToCartPOST(
  customerId,
  productId,
  modelId,
  quantity,
  price,
  event,
  accessories
) {
  return await axiosInstance.post("api/app/cart/addToCart", {
    customerId,
    productId,
    modelId,
    quantity,
    price,
    event,
    accessories,
  });
}

//POST: cart check out API
export async function checkOutPOST(
  cartId,
  addressId,
  newAddress,
  orderType,
  customerRef,
  comments,
  contactPhone
) {
  return await axiosInstance.post("api/app/cart/checkOut", {
    cartId,
    addressId,
    newAddress,
    orderType,
    customerRef,
    comments,
    contactPhone,
  });
}

//POST: update cart item quantity
export async function updateCartItemPOST(cartProductId, quantity) {
  return await axiosInstance.post("api/app/cart/updateQuantity", {
    cartProductId,
    quantity,
  });
}

//GET: remove cart item quantity
export async function removeCartItemPOST(cartProductId) {
  return await axiosInstance.get("api/app/cart/removeProduct/" + cartProductId);
}

//POST: get sales/quotation data API
export async function getSalesDataPOST(
  orderType,
  reference,
  from,
  till,
  displayStart,
  displayLength
) {
  return await axiosInstance.post("api/app/sales/list", {
    orderType,
    reference,
    from,
    till,
    displayStart,
    displayLength,
  });
}

//GET: sales/quotation details API
export async function salesDetailsPOST(orderNo) {
  return await axiosInstance.get("api/app/sales/details/" + orderNo);
}

//GET: quotation to sales changes API
export async function quotToSalesGET(orderId) {
  return await axiosInstance.get("api/app/sales/changeQuotToSale/" + orderId);
}

//POST: get All invoices API
export async function allSalesInvoiceListPOST(
  reference,
  from,
  till,
  displayStart,
  displayLength
) {
  return await axiosInstance.post("api/app/sales/invoice/search", {
    reference,
    from,
    till,
    displayStart,
    displayLength,
  });
}

//GET: sales invoices API
export async function salesInvoiceListGET(orderId) {
  return await axiosInstance.get("api/app/sales/invoice/list/" + orderId);
}

//GET: sales invoice details API
export async function salesInvoiceDataGET(invoiceId) {
  return await axiosInstance.get("api/app/sales/invoice/3/" + invoiceId);
}

//GET: sales payments API
export async function salesInvoicePaymentGET(orderId) {
  return await axiosInstance.get("api/app/sales/payments/" + orderId);
}

//POST: get All payments API
export async function paymentListPOST(
  reference,
  from,
  till,
  displayStart,
  displayLength
) {
  return await axiosInstance.post("api/app/sales/payments", {
    reference,
    from,
    till,
    displayStart,
    displayLength,
  });
}

//GET: sales delivery List API
export async function salesDeliveryListGET(orderId) {
  return await axiosInstance.get("api/app/sales/delivery/list/" + orderId);
}

//POST: get All deliveries API
export async function deliveryListPOST(
  reference,
  from,
  till,
  displayStart,
  displayLength
) {
  return await axiosInstance.post("api/app/sales/delivery/list", {
    reference,
    from,
    till,
    displayStart,
    displayLength,
  });
}

//GET: delivery details API
export async function salesDeliveryDetailsGET(dispatchId) {
  return await axiosInstance.get("api/app/sales/delivery/" + dispatchId);
}

//GET: sales return item List API
export async function salesReturnItemsListGET(orderId) {
  return await axiosInstance.get("api/app/sales/returnItem/list/" + orderId);
}

//GET: currency List API
export async function currencyListGET() {
  return await axiosInstance.get("api/app/user/currency");
}

//GET: country List API
export async function countryListGET() {
  return await axiosInstance.get("api/app/user/country");
}

//GET: country List API
export async function countryList2GET() {
  return await axiosInstance.get("api/app/country");
}

//GET: user data API
export async function userDataGET() {
  return await axiosInstance.get("api/app/user/");
}

//get products by category or brands
export async function getProductsByCatOrBrands(
  categoryId,
  displayStart,
  displayLength,
  brands,
  orderBy
) {
  return await axiosInstance.post("api/app/product/category", {
    categoryId,
    displayStart,
    displayLength,
    brands,
    orderBy,
  });
}

//create sign up user
export async function createSignUpUser(
  name,
  companyRegNo,
  vatRegNo,
  email,
  phone,
  addressline01,
  addressline02,
  addressline03,
  postCode,
  countryId
) {
  return await axiosInstance.post("api/app/signup", {
    name,
    companyRegNo,
    vatRegNo,
    email,
    phone,
    addressline01,
    addressline02,
    addressline03,
    postCode,
    countryId,
  });
}

//POST: unlocked User account
export async function unlockUserAccount(resetToken) {
  return await axiosInstance.post("api/app/unlocked-account", { resetToken });
}

//POST: cart check out API
export async function submitChangePassword(
  currentPassword,
  newPassword,
  confirmNewPassword
) {
  return await axiosInstance.post("api/app/tfChangePassword", {
    currentPassword,
    newPassword,
    confirmNewPassword,
  });
}

//POST: Third Party Customer Save
export async function saveTpCustomer(
  orderId,
  customerId,
  name,
  email,
  contact,
  addL1,
  addL2,
  addL3,
  postCode,
  country,
  newcus
) {
  return await axiosInstance.post("api/app/customer/saveThirdPartyCustomer", {
    orderId,
    customerId,
    name,
    email,
    contact,
    addL1,
    addL2,
    addL3,
    postCode,
    country,
    newcus,
  });
}

//GET: Third Party Customer By parent API
export async function getTpCustomers() {
  return await axiosInstance.get("api/app/customer/getTpCustomer");
}

//POST: Thired Part Document Header details
export async function saveDocHeaderData(
  headerId,
  coRegNo,
  coVatNo,
  coEmail,
  coContact,
  coFaxNo
) {
  return await axiosInstance.post("api/app/customer/saveB2BDocHeaderData", {
    headerId,
    coRegNo,
    coVatNo,
    coEmail,
    coContact,
    coFaxNo,
  });
}

//GET: Third Party Customer Doc Header API
export async function getTpCustomerDcoHeaderData() {
  return await axiosInstance.get("api/app/customer/getTpCustomerDcoHeaderData");
}
//GET: promotion API
export async function getGiftSet() {
  return await axiosInstance.get("api/promotion/b2b");
}

//GET: promotion details API
export async function promotionDetailsGET(promId) {
  return await axiosInstance.get("api/promotion/b2b/promotionBy/" + promId);
}

//GET: promotion details by product API
export async function getGiftItemByProduct(proId) {
  return await axiosInstance.get("api/promotion/b2b/giftset/" + proId);
}

//GET: Prodcut Availability by product API
export async function getProdcutAvailability(proId, modelId) {
  return await axiosInstance.get(
    "api/app/product/prodcutAvailability/" + proId + "/" + modelId
  );
}

//POST: Prodcut Availability check
export async function checkTp2Availability(brand, sku, ean, paQuantity) {
  return await axiosInstance.get(
    "api/app/product/checkTp2Availability/" +
      brand +
      "/" +
      sku +
      "/" +
      ean +
      "/" +
      paQuantity
  );
}
